<template>
  <el-card shadow="always">
    <div style="margin-top: 20px">
      <el-form :model="ruleForm" ref="ruleForm" label-width="140px" size="mini">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="基础管理" name="first">

            <el-form-item label="公司名称">
              <el-input
                v-model="ruleForm.shopname"
                class="el_input"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="ruleForm.linkname" class="el_input"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="ruleForm.linkmobile" class="el_input"></el-input>
            </el-form-item>

            <el-form-item label="地址">
              <el-input
                v-model="ruleForm.address"
                class="el_input"
              ></el-input>
            </el-form-item>

          
          <el-form-item label="logo" :label-width="formLabelWidth">
                <el-image
                :src="ruleForm.logo"
                style="width: 100px; height: 100px"
                :preview-src-list="[ruleForm.logo]"
                >
                </el-image>
                
                
               
                <el-upload
                class="upload-demo"
                action="/admin/Upload/uploadImage"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                >
                 <el-button @click="changeFiled('logo')">上传</el-button>
                </el-upload>
                
          </el-form-item>
          </el-tab-pane>
          <!-- <el-tab-pane label="关于我们" name="about">
            <el-form-item label="内容" :label-width="formLabelWidth">
              <editor-bar
                v-model="ruleForm.index_about_content"
                :isClear="isClear"
                @change="change"
              ></editor-bar> </el-form-item
          ></el-tab-pane>
          <el-tab-pane label="团队介绍" name="team">
            <el-form-item label="内容" :label-width="formLabelWidth">
              <editor-bar
                v-model="ruleForm.index_team_content"
                :isClear="isClear"
                @change="change"
              ></editor-bar>
            </el-form-item>
          </el-tab-pane> -->
        </el-tabs>

        <el-form-item>
          <el-button
            type="primary"
            @click="saveData"
            v-loading.fullscreen.lock="fullscreenLoading"
            >提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="图片查看" :visible.sync="dialogVisible" width="30%">
      <el-image :src="show_image"></el-image>
    </el-dialog>
      <el-dialog title="请选择图片"  width="80%" :visible.sync="showimage" :close-on-click-modal="false">
        <select-image @onSelectImage="onSelectImage" :imagetype="imagetype"></select-image>
      </el-dialog>
  </el-card>
</template>

<script>
// @ is an alias to /src
import EditorBar from "/src/components/wangEnduit.vue";
import selectImage from "@/components/image/image.vue";
export default {
  name: "edit",
  components: { EditorBar,selectImage },
  data() {
    return {
      ruleForm: {},
      activeName: "first",
      url: "/Index/",
      fullscreenLoading: false,
      show_image: "",
      dialogVisible: false,
      filedName: "",
      isClear: true,
      showimage:false,
      imagetype:'one'
    };
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    changeFiled(fileName) {
      this.filedName = fileName;
    },

    getAllData() {
      let _this = this;
      _this.getRequest(_this.url + "getInfo").then((resp) => {
        if (resp) {
          _this.ruleForm = resp.data;
        }
      });
    },
    handleSuccess(res) {
      this.ruleForm[this.filedName] = res.data.url;
      this.saveData();
    },

    beforeUpload(file) {
      const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(file.type);
      if (!isTypeTrue) {
        this.$message.error("上传图片格式不对!");
        return;
      }
    },
    delImage(file, fileName) {
      let _this = this;
      _this
        .getRequest("/unit.Upload/delImage?fileName=" + file)
        .then((resp) => {
          if (resp.code == 200) {
            _this.ruleForm[fileName] = "";
            _this.saveData();
            _this.$message.success("删除成功!");
          } else {
            _this.$message.error(resp.msg);
          }
        });
    },
    showImage(file) {
      this.show_image = file;
      this.dialogVisible = true;
    },
    openImage(field, imagetype) {
      _this.showimage = true;
      _this.imagetype = imagetype;
      _this.selectField = field;
    },
    removeImage(field, imagetype, index) {
      if (imagetype == "one") {
        _this.formData[field] = "";
      } else {
        _this.formData[field].splice(index, 1);
      }
    },
    handleClick() {},
    saveData() {
      let _this = this;
      _this.fullscreenLoading = true;
      var form = new FormData();        
      form.append('address', this.ruleForm.address);
      form.append('shopname', this.ruleForm.shopname);
      form.append('mobile', this.ruleForm.mobile);
      form.append('logo', this.ruleForm.logo);
      this.postRequest(_this.url + "save", form).then((resp) => {
        if (resp.code == 200) {
          _this.$message({
            type: "success",
            message: resp.msg,
          });
        }
        _this.fullscreenLoading = false;
      });
    },
  },
};
</script>
<style>


.el_input {
  width: 600px;
}


</style>
